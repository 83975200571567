













import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import { format, isValid, parse } from "date-fns";
import { dateFormatMap, toDateObject } from "../modules/utils/datetime";

export default defineComponent({
  name: "DatePicker",
  props: {
    placeholder: String,
    value: String,
    required: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const appDateStr: Ref<string | null> = ref(null);
    const appDate: Ref<Date | null> = ref(null);

    const formatDate = (
      dateStr: string | undefined | null,
      strFormat: string
    ) => {
      const dateObj = parse(dateStr ?? "", dateFormatMap.system, new Date());
      return isValid(dateObj) ? format(dateObj, dateFormatMap[strFormat]) : null;
    };

    const formatDateStr = (date: Date | null) => {
      if (!date) return null;
      return isValid(date) ? format(date, dateFormatMap.system) : null;
    };

    if (props.value) {
      appDateStr.value = formatDate(props.value, "system");
      if (appDateStr.value) {
        appDate.value = toDateObject(appDateStr.value);
      }
    }

    // watch ketika props.value berubah (selesai loading fetch)
    watch(
      () => props.value,
      (newValue) => {
        if (appDateStr.value !== newValue) {
          appDateStr.value = formatDate(newValue, "system");
          if (appDateStr.value) {
            appDate.value = toDateObject(appDateStr.value);
          }
        }
      }
    );

    const onBlur = () => {
      appDateStr.value = formatDateStr(appDate.value);
      emit("input", appDateStr.value);
    };

    return {
      // Data
      appDate,
      appDateStr,

      // Method
      onBlur,
    };
  },
});
